import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CurrentInvestmentModel } from '../modules/shared/components/investment-edit/state';

@Injectable()
export class InvestmentService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getMemberSwitchTransactions(memberId: number, accountId: number, page: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('accountId', accountId.toString())
      .set('page', page.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/switchtransactions`, { headers: this.headers(), params: params });
  }


  getInvestmentSwitch(accountId: number): Observable<any> {
    const params = new HttpParams()
      .set('accountId', accountId.toString());

    return this.http.get(environment.apiUrl + `/member-portal/Investment/account/${accountId}/investment`, { headers: this.headers(), params: params });
  }

  getCurrentInvestmentSwitch(accountId: number): Observable<any> {
    const params = new HttpParams()
      .set('accountId', accountId.toString());

    return this.http.get(environment.apiUrl + `/member-portal/Investment/account/${accountId}/current-investment`, { headers: this.headers(), params: params });
  }

  getInvestmentOptionsSwitch(accountId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/Investment/account/${accountId}/investment-options`, { headers: this.headers() });
  }

  submitInvestment(accountId: number, obj: CurrentInvestmentModel): Observable<any> {
    return this.http.post<CurrentInvestmentModel>(environment.apiUrl + `/member-portal/Investment/account/${accountId}/investment-submit`, obj, { headers: this.headers() });
  }
}
