<div id="insurance-questionnaire-container" *ngIf="showQuestionnaire$ | async">
  
  <form [ngrxFormState]="form" *ngIf="form$ | async; let form">
    <div class="description">
      <h3>Would you like insurance cover paid from your super?</h3>
      <p
        [innerHTML]="('requestInsurance_Description' | message | async)?.replaceAll('{date}', elegibilityDates$ | async | date:'dd/MM/yyyy')">
      </p>
      <p>
        <app-checkbox title="{{'requestInsurance_ConfirmReadInsuranceGuideText' | message | async}}" [control]="form.controls.confirmReadInsuranceRequestInsuranceGuide">
        </app-checkbox>
      </p>
    </div>

    <div class="components-actions">
      <app-button class="" [isDisabled]="!form.isValid" title="Start Questionnaire" icon="east" (onClick)="onStartClick()">
      </app-button>
    </div>
  </form>
</div>