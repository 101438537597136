import { IContributionsLimitsState } from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemberPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';


export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const contributionsLimit = createSelector(memberPortalState, (state) => state.contributionsLimitsState);

export const contributionsLimit_Data = createSelector(
  contributionsLimit,
  (state: IContributionsLimitsState) => state.data
);
