import { unitPricesView_Model } from './selectors';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
  ApexTooltip,
  ApexFill,
  ApexYAxis,
  ApexMarkers
} from "ng-apexcharts";
import { Helper } from '@ifaa-components/ui-components';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  //   fill: ApexFill;
  //markers: ApexMarkers;
};

@Component({
  selector: 'app-unit-prices-view',
  templateUrl: './unit-prices-view.component.html',
  styleUrls: ['./unit-prices-view.component.scss']
})
export class UnitPricesViewComponent extends ComponentBase implements OnInit, OnDestroy {
  helper = new Helper();
  model$ = this.store.pipe(select(unitPricesView_Model));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  @ViewChild("chart2") chart: ChartComponent;

  public chartOptions: Partial<ChartOptions>;

  constructor(public store: Store<MemberPortalSharedState>) {
    super();
    this.chartOptions = {
      tooltip: {
        enabled: true,
      },
      series: [
        {
          name: "Unit price",
          data: []
        }
      ],

      chart: {
        height: 350,
        type: "area",
        zoom: {
          enabled: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        text: "",
        align: "left"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.1
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return "$" + value;
          }
        },
      },
      xaxis: {
        type: 'datetime'
      },
    }
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    });

    this.sub = this.model$.subscribe(x => {
      if (x && this.chart) {
        this.chart.updateSeries([{
          data: x.data
        }])
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}

