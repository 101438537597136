import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IBPAYDetailsViewState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getBPAYDetails = createSelector(memberPortalState, (state) => state.bpayDetailsViewState);

export const getBPAYDetails_Item = createSelector(
  getBPAYDetails,
  (state: IBPAYDetailsViewState) => state.item
);


