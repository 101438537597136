import { NotificationModel } from './../../../../model/notification.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';

export const RequestAction = createAction('[notification dropdown] request',  props<{ accountId: number }>());

export const ResponseAction = createAction('[notification dropdown] response ',
  props<{ payload: NotificationModel[] }>());



