import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IConsolidateSuperState } from './state';
import { featureKey } from '../../store/shared.reducers';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const consolidateSuper = createSelector(memberPortalState, (state) => state.consolidateSuperState);

export const consolidateSuper_List = createSelector(
  consolidateSuper,
  (state: IConsolidateSuperState) => state.list
);
