import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SwitchInvestmentsService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getOptions(accountId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/SwitchInvestments/account/${accountId}/options`, { headers: this.headers() });
  }

  submit(accountId: number, selectedId: number): Observable<any> {
    // const params = new HttpParams()
    // .set('accountId', accountId.toString());
    var obj = {
      selected: selectedId
    }
    return this.http.post<any>(environment.apiUrl + `/member-portal/SwitchInvestments/account/${accountId}`, obj, { headers: this.headers() });
  }
}
