import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { ContributionDetailModel } from 'src/app/model/contributions-limit.model';

export const RequestAction = createAction('[Contribution limit] get',
  props<{ accountId: number }>());

export const ResponseAction = createAction('[Contributions limit] set transaction',
  props<{ payload: ContributionDetailModel }>());

export const ResetFormAction = createAction('[Contributions limit] reset form');
