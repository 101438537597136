import { AppFileModel } from "@ifaa-components/ui-components";

import { FormGroupState } from "ngrx-forms";
import { BankDetailsModel } from "src/app/model/bank-details.model";

export interface IJetcoLodgeClaimState {
  form: FormGroupState<JetcoLodgeClaimModel>;
  claimAmount: number;
  lastModifiedTime: Date;
}

export class JetcoLodgeClaimModel {
  trainingProvider: string = '';
  trainingCourse: string = '';
  startDate: string = '';
  endDate: string = '';
  costOfTraining: number = 0;
  receiving: boolean = false;
  receivingHowMuch: number = 0;
  bankDetails: BankDetailsModel = new BankDetailsModel();
  invoice: boolean = false;
  completion: boolean = false;
  declaration: boolean = false;
  // attachments: AppFileModel[] = [new AppFileModel()];
  invoiceAndReceipt: AppFileModel = new AppFileModel();
  completionCertificate: AppFileModel = new AppFileModel();
  bankStatement: AppFileModel = new AppFileModel();
  certifiedId: AppFileModel = new AppFileModel();
  completedEmployeeTraining: AppFileModel = new AppFileModel();
}
