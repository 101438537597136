import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/smrt.reducers';
import { SMRTState } from '../../store/smrt.states';
import { ILodgeClaimState } from './state';

export const memberPortalState = createFeatureSelector<SMRTState>(featureKey);

export const lodgeClaim = createSelector(memberPortalState, (state) => state.lodgeClaim);

export const lodgeClaim_Form = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.form
);

export const lodgeClaim_claimDocumentationList = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.form.controls.attachments.controls
);

export const lodgeClaim_CurrentStep = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.currentStep
);

export const lodgeClaim_TotalSteps = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.totalSteps
);

export const lodgeClaim_Validated = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.validated
);

export const lodgeClaim_EmployerList = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.form.controls.employers.controls
);

export const lodgeClaim_LastModifiedTime = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.lastModifiedTime
);


