import { insurancePreQuestionnaire_Form } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { commomState_SystemConfig_Config, commomState_SystemConfig_Messages } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';
import { ShowStartQuestionnaireRequestAction } from './actions';


@Component({
  selector: 'app-insurance-pre-questionnaire',
  templateUrl: './insurance-pre-questionnaire.component.html',
  styleUrls: ['./insurance-pre-questionnaire.component.scss']
})
export class InsurancePreQuestionnaireComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  messages$ = this.appstore.pipe(select(commomState_SystemConfig_Messages));
  form$ = this.store.pipe(select(insurancePreQuestionnaire_Form));

  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {

      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onStartClick() {
    this.dispatch(this.store,ShowStartQuestionnaireRequestAction({display: true}));
  }

}
