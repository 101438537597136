import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../../store/cirt.reducers';
import { CIRTState } from '../../../store/cirt.states';
import { ICurrentEmployerViewState } from './state';
export const memberPortalState = createFeatureSelector<CIRTState>(featureKey);

export const currentEmployer = createSelector(memberPortalState, (state) => state.currentEmployer);

export const currentEmployer_Employer = createSelector(
  currentEmployer,
  (state: ICurrentEmployerViewState) => state.employer
);
