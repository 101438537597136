import { CashTransactionFilter, ICashTransactionListState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction, createFormControlState, createFormGroupState, setValue
} from 'ngrx-forms';
import {
  CashTransactionsResponseAction,
  CashTransactionIncrementPageAction, CashTransactionsTypesResponseAction, ResetFormAction,
  CashTransactionsSetFromToDateAction,
  CashTransactionsDescriptionResponseAction
} from './actions';
import { Helper } from '@ifaa-components/ui-components';

export const cashtransactionsListFilter = 'cashtransactionsListFilter';
var helper = new Helper();

export const cashTransactionListState: ICashTransactionListState = {
  list: [],
  transactionTypes: [],
  transactionDescription: [],
  filter: createFormGroupState(cashtransactionsListFilter, new CashTransactionFilter()),
};

const reducer = createReducer(cashTransactionListState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (action.controlId == 'cashtransactionsListFilter.from'
      || action.controlId == 'cashtransactionsListFilter.to'
      || action.controlId == 'cashtransactionsListFilter.transactionType'
      || action.controlId == 'cashtransactionsListFilter.transactionDescription') {
      var newFilter = {
        ...state.filter.value,
        currentPage: 0
      };
      return {
        ...state,
        filter: setValue(newFilter)(cashTransactionListState.filter)
      };
    }

    return state;
  }),
  on(CashTransactionsSetFromToDateAction, (state, { from, to }) => {
    var clone = helper.clone(state.filter.value);
    clone.from = from;
    clone.to = to;
    clone.currentPage = 0;

    return {
      ...state,
      filter: setValue(clone)(state.filter),
    }
  }),
  on(CashTransactionsResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.filter.value);
    //clone.currentPage = payload.length == 0 ? -1 : state.filter.value.currentPage
    return {
      ...state,
      list: payload,// state.filter.value.currentPage === 0 ? payload : state.list.concat(payload),
      filter: setValue(clone)(state.filter),
    }
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      list: [],
      transactionTypes: [],
      transactionDescription: [],
      filter: createFormGroupState(cashtransactionsListFilter, new CashTransactionFilter()),
    }
  }),
  on(CashTransactionsTypesResponseAction, (state, { payload }) => {
    return {
      ...state,
      transactionTypes: payload
    }
  }),
  on(CashTransactionsDescriptionResponseAction, (state, { payload }) => {
    return {
      ...state,
      transactionDescription: payload
    }
  }),
  on(ResetFormAction, (state) => {
    return {
      list: [],
      transactionTypes: [],
      transactionDescription: [],
      filter: createFormGroupState(cashtransactionsListFilter, new CashTransactionFilter()),
    }
  }),
  on(CashTransactionIncrementPageAction, (state) => {
    if (state.filter.value.currentPage === -1)
      return state;

    var clone = helper.clone(state.filter.value);

    clone.currentPage = state.filter.value.currentPage + 1;

    return {
      ...state,
      filter: setValue(clone)(cashTransactionListState.filter)
    };
  }),
);

export function cashTransactionListReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
