import { MenuModel } from './../../../../model/menu.model';
// import { MemberModel } from '../../../model/member.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';

export const MainExpandedRequestAction = createAction('[Main View] expand item',
  props<{ menuItem: MenuModel }>());

 export const MainSetAnimatedPageAction = createAction('[Main View] animated');

// export const MainSearchResetPageAction = createAction('[Main View] set 0 to current page');

