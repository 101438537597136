import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IRollInEditState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const editRollIn = createSelector(memberPortalState, (state) => state.rollInEditState);

export const editRollIn_Form = createSelector(
  editRollIn,
  (state: IRollInEditState) => state.form
);

export const editRollIn_LastModified = createSelector(
  editRollIn,
  (state: IRollInEditState) => state.lastModifiedTime
);
