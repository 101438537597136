<div id="employer-balances-container">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Employer Name</th>
        <th scope="col">Start Date</th>
        <th scope="col">End Date</th>
        <th scope="col">CIRT Severance Contribution Total</th>
        <th scope="col">Amount Claimed</th>
        <th scope="col">Available Balance</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data$|async">
        <th>{{item.employerName}}</th>
        <th>{{item.startDate | date:'dd/MM/yyyy'}}</th>
        <th>{{item.endDate | date:'dd/MM/yyyy'}}</th>
        <th>{{item.employerSeverance | currency | dash}}</th>
        <th>{{item.severanceClaimed | currency | dash}}</th>
        <th>{{item.availableBalance | currency | dash}}</th>
      </tr>
    </tbody>
  </table>

</div>
