import { OkModel } from './../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { SwitchInvestmentModel } from 'src/app/model/switch-investment.model';

export const RequestOptionsAction = createAction('[Switch Investment Edit] request data ', props<{ accountId: number }>());

export const ResponseOptionsAction = createAction('[Switch Investment Edit] response data ',
  props<{ payload: SwitchInvestmentModel }>());


  export const RequestSwitchOptionAction = createAction('[Switch Investment Edit] request switch option ', props<{ accountId: number, selectedId: number }>());

export const ResponseSwitchOptionAction = createAction('[Switch Investment Edit] response switch option ',
  props<{ payload: OkModel<string> }>());
