import { FormGroupState } from 'ngrx-forms';
import { AccountUnderlyingModel } from './../../../../model/account-underlying.model';

export interface IChatViewState {
  model: TwilioModel;
  isChatOpened: boolean;
  form: FormGroupState<TwilioChatModel>;
  lastUpdated?: Date;
  loaded: boolean;
}

export class TwilioChatModel {
  message: string = '';
}


export class TwilioModel {
  twilioToken: string = '';
  twilioChannel: string = '';
  conversationHistory: ConversationHistoryModel[];
}

export class ConversationHistoryModel {
  author: string = '';
  isMemberMessage: boolean = false;
  body: string = '';
  date: string = '';
}
