import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

@Injectable()
export class MemberCorrespondenceService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }



  getMemberCorrespondece(memberId: number, page: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('page', page.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/correspondence`, { headers: this.headers(), params: params });
  }

  getMemberCorrespondeceFile(memberId: number, correspondenceSource: number, correspondenceId: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('correspondenceSource', correspondenceSource.toString())
      .set('correspondenceId', correspondenceId.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/correspondence/${correspondenceSource}/${correspondenceId}`, { headers: this.headers(), params: params });
  }

}
