import { BeneficiaryModel } from './../../../../model/beneficiary.model';
import { beneficiaryEdit_Form, beneficiaryEdit_LastModified, beneficiaryEdit_IsSum100Percent, beneficiaryEdit_TotalPercent, beneficiaryEdit_CanEdit, beneficiaryEdit_CanAdd, beneficiaryEdit_MaxBeneficiaries } from './selectors';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { AddNewBeneficiaryAction, RemoveBeneficiaryAction, RequestAction, ResetAction, SetupMaxBeneficiariesAction, SubmitRequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { MatDialog } from '@angular/material/dialog';
import { CancelEditDialog } from '@ifaa-components/ui-components';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { Router } from '@angular/router';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';
import { UIComponentState } from '@ifaa-components/ui-components/lib/store/app.states';
import { AppState } from 'src/app/store/app.states';

@Component({
  selector: 'app-beneficiaries-edit',
  templateUrl: './beneficiaries-edit.component.html',
  styleUrls: ['./beneficiaries-edit.component.scss']
})
export class BeneficiariesEditComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(beneficiaryEdit_Form));
  lastModified$ = this.store.pipe(select(beneficiaryEdit_LastModified));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  isSum100Percentage$ = this.store.pipe(select(beneficiaryEdit_IsSum100Percent));
  totalPercentage$ = this.store.pipe(select(beneficiaryEdit_TotalPercent));
  canEdit$ = this.store.pipe(select(beneficiaryEdit_CanEdit));
  accountId: number = 0;
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  maxBeneficiaries$ = this.uistore.pipe(select(beneficiaryEdit_MaxBeneficiaries));
  canAdd$ = this.uistore.pipe(select(beneficiaryEdit_CanAdd));

  // {{(tenantConfig$ | async).phoneNumber}}
  constructor(public store: Store<MemberPortalSharedState>,
    public uistore: Store<UIComponentState>,
    public appstore: Store<AppState>,
    public dialog: MatDialog,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.dispatch(this.store, ResetAction());

    this.sub = this.tenantConfig$.subscribe(x => {
      if (x && x.beneficiariesMax) {
        this.store.dispatch(SetupMaxBeneficiariesAction({ value: x.beneficiariesMax }))
      }
    });
    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.router.navigate(['beneficiaries']);
      }
    });

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.accountId = x.accountId;
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    })

  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onDeleteClick(index: number) {
    const dialogRef = this.dialog.open(CancelEditDialog, {
      width: '450px',
      data: 'Do you want to remove this beneficiary?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(RemoveBeneficiaryAction({ index }));
      }
    });
  }

  onSubmitClick(beneficiaries: BeneficiaryModel[]) {
    this.store.dispatch(SubmitRequestAction({ accountId: this.accountId, payload: beneficiaries }));
  }
  onAddClick() {
    this.store.dispatch(AddNewBeneficiaryAction());
  }
}
