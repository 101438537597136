import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { BPAYDetailsModel } from 'src/app/model/bpay-details.model';


export const RequestAction = createAction('[BPAY Details view] get data', props<{ accountId: number }>());
export const ResponseAction = createAction('[BPAY Details view] set data',  props<{ payload: BPAYDetailsModel }>());



