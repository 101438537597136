import { featureKey } from './../../store/shared.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IChatViewState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getChatView = createSelector(memberPortalState, (state) => state.chatViewState);

export const getChat_Model = createSelector(
  getChatView,
  (state: IChatViewState) => state.model
);


export const getChat_IsChatOpened = createSelector(
  getChatView,
  (state: IChatViewState) => state.isChatOpened
);

export const getChat_Form = createSelector(
  getChatView,
  (state: IChatViewState) => state.form
);

export const getChat_LastUpdated = createSelector(
  getChatView,
  (state: IChatViewState) => state.lastUpdated
);

export const getChat_ChatLoaded = createSelector(
  getChatView,
  (state: IChatViewState) => state.loaded
);
