import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { ISuperannuationProviderSearchState } from './state';
import { KeyValueModel } from '@ifaa-components/ui-components';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const superannuationProviderSearch = createSelector(memberPortalState, (state) => state.superannuationProviderSearchState);

export const superannuationProviderSearch_Form = createSelector(
  superannuationProviderSearch,
  (state: ISuperannuationProviderSearchState) => state.form
);

export const superannuationProviderSearch_LastModified = createSelector(
  superannuationProviderSearch,
  (state: ISuperannuationProviderSearchState) => state.lastModifiedTime
);

export const superannuationProviderSearch_List = createSelector(
  superannuationProviderSearch,
  (state: ISuperannuationProviderSearchState) => state.form.value.list);
