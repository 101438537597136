import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMemberPensionViewState } from './state';
import { MemberPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getMemberPensionView = createSelector(memberPortalState, (state) => state.memberPensionViewState);

export const getMemberPensionView_Model = createSelector(
  getMemberPensionView,
  (state: IMemberPensionViewState) => state.model
);

export const getMemberPensionView_beneficiaryList = createSelector(
  getMemberPensionView,
  (state: IMemberPensionViewState) => state.beneficiaryList
);
