import { AppState } from 'src/app/store/app.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMemberViewOverviewState } from './state';
import { MemberPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const memberViewOverview = createSelector(memberPortalState, (state) => state.memberViewOverviewState);

export const memberViewOverview_Model = createSelector(
  memberViewOverview,
  (state: IMemberViewOverviewState) => state.model
);
