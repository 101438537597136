export class SwitchInvestmentModel {
  selected: number = 0;
  newSelected: number = 0;
  options: SwitchInvestmentOptionModel[] = [];
}

export class SwitchInvestmentOptionModel {
  id: number = 0;
  title: string = '';
  subTitle: string = '';
  descriptionList: string[] = [];
  selected: boolean = false;
}
