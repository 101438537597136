import { AddressModel } from "@ifaa-components/ui-components";
import { CommunicationPreference } from '../enums/communication-preference.enum';

export class MemberModel {
  memberNumber: string = '';
  firstName: string = '';
  lastName: string = '';
  secondName: string = '';
  taxFileNumber: string = '';
  mobile: string = '';
  homePhone: string = '';
  occupation: string = '';
  email: string = '';
  postalAddress: AddressModel = new AddressModel();
  residentialAddress: AddressModel = new AddressModel();
  dateOfBirth: string = '';
  isPostalSameAsResidential: boolean = false;
  hasTFN: boolean = false;
  preferredName: string = '';
  tfnTermsAndCondition: boolean = false;
  communicationPreference: number = CommunicationPreference.unknown;
}
