<div id="personal-contributions-edit-container">
  <div *ngIf="!(lastModified$ | async)">
    <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
      <div class="description" [innerHTML]="'personalContributions_HeaderText'| message | async"></div>

      <div class="step mt-4">
        <h4 class="title"> Step 1: Eligibility to contribute</h4>
        <p>
          By making this contribution I confirm that I meet one of the eligibility requirements below:
        </p>
        <app-entry-radio list="elegibilityToContribute" [control]="form.controls.eligibilityToContribute">
        </app-entry-radio>
        <div class="alert alert-info mt-3">
          * In accordance with superannuation legislation, you must be no older than 28 calendar days past the date of
          your 75th birthday if you want to make a personal contribution (other than a downsizer contribution) to your
          superannuation account. If you fail to meet this criterion on the date your contribution is received, it will
          be returned to you.
        </div>
      </div>
      <div class="step">
        <h4 class="title"> Step 2: Your contribution frequency</h4>
        <p>
          How often will you be making this contribution?
        </p>
        <app-entry-radio list="contributeFrequency" [control]="form.controls.contributeFrequency">
        </app-entry-radio>
        <div class="alert alert-info mt-3">
          You must use the same amount, frequency and payment reference if you chose to make your contribution
          regularly.
        </div>

      </div>
      <div class="step">
        <h4 class="title"> Step 3: Your contribution amount</h4>
        <p>
          Please enter the exact amount of your personal contribution.
        </p>
        <app-entry-number title="Contribution" class="attach_money d-block"
          [control]="form.controls.personalContribution" icon="attach_money">
        </app-entry-number>

        <p class="mt-3">How many bank payments do you intend to place to pay the whole contribution amount?</p>
        <app-entry-radio list="bankPayments" [control]="form.controls.bankPayments">
        </app-entry-radio>
        <div class="alert alert-info mt-3">
          Sometimes members split their contributions into multiple payments due to the limits imposed by their online
          banking facility. By providing this information, it helps us identify and match our bank statement with our
          contribution forms.
        </div>

      </div>

      <div class="step">
        <h4 class="title"> Step 4: Your payment method</h4>
        <div class="d-flex flex-row widgets-row">
          <div class="w-50 ">
            <p>
              Which payment method will you be using to make this contribution?
            </p>
            <app-entry-radio list="paymentsMethods" [control]="form.controls.paymentsMethods">
            </app-entry-radio>

          </div>
          <div class="w-50">
            <div class="alert alert-info">
              <div *ngIf="form.value.paymentsMethods == 0">
                Please use the following payment details when making your contribution via BPAY
                <div class="bpay d-flex flex-column mt-2 mb-2">
                  <div class="d-flex flex-row">
                    <div>
                      <img src="https://bpay.com.au/Common/images/bpay-logo.svg">
                    </div>
                    <div class="data">
                      <div class="entry d-flex ">
                        <div style="width: 75px;">Biller Code:</div>
                        <div class="description" [innerHTML]="'personalContributions_BPAY_Code'| message | async"></div>
                      </div>
                      <div class="entry d-flex">
                        <div style="width: 75px;">Ref:</div>
                        <div class="description" [innerHTML]="'personalContributions_BPAY_Ref'| message | async"></div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-2">
                    <div class="telandint">Telephone & Internet Banking - BPAY</div>
                    <label>Contact your bank or financial institution to make this payment from your cheque, savings,
                      debit, credit
                      card or transaction account. More info <a
                        href="http://www.bpay.com.au">www.bpay.com.au</a></label>
                  </div>
                </div>

              </div>
              <div *ngIf="form.value.paymentsMethods == 1">
                Please use the following payment details when making your non-concessional contribution via direct
                deposit.
                <div class="dd d-flex flex-column mt-2 mb-2">
                  <div class="d-flex flex-row">

                    <div class="data" *ngIf="bankDetails$ | async; let bank">
                      <div class="entry d-flex ">
                        <div class="w-50">BSB:</div>
                        <div class="description" [innerHTML]="bank.bsb">
                        </div>
                      </div>
                      <div class="entry d-flex">
                        <div class="w-50">Account Number:</div>
                        <div class="description" [innerHTML]="bank.accountNumber">
                        </div>
                      </div>
                      <div class="entry d-flex">
                        <div class="w-50">Account Name:</div>
                        <div class="description" [innerHTML]="bank.accountName">
                        </div>
                      </div>
                      <div class="entry d-flex">
                        <div class="w-50">Reference:</div>
                        <div class="description">{{ 'PERSONAL_CONTRIBUTION_REFERENCE_PREFIX' | translate }} {{bank.reference}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              By Submitting this form at the same time as making your payment, we will be able to quickly allocate your
              contribution to your account.
            </div>
          </div>
        </div>
      </div>

      <div class=" alert step"
        [ngClass]="{'alert-warning': form.isInvalid, 'alert-success': checkIsDeclatarionCompleted(form.value)}">
        <h4 class="title"> Step 5: Your declaration</h4>
        <p>
          After checking your details above, please tick the boxes below to confirm you have read, understood and agree
          to the declarations below.
        </p>
        <app-checkbox [control]="form.controls.declaration_Option1"
          title="{{'PERSONAL_CONTRIBUTION_DECLARATION_1'|translate}}">
        </app-checkbox>

        <app-checkbox [control]="form.controls.declaration_Option2"
          title="I understand that in order to claim this personal contribution as a tax deduction, I must meet the ATO’s eligibility requirements as outlined on the Notice of Intent to Claim or Vary a Deduction for Personal Super Contributions form. If I am aged between 67 and 75 I understand that I also need to meet the Work Test, or be eligible for the Work Test Exemption.">
        </app-checkbox>

        <app-checkbox [control]="form.controls.declaration_Option3"
          title="I understand that if the contribution exceeds a contribution cap, additional tax may be payable on the excess amount.">
        </app-checkbox>

        <app-checkbox [control]="form.controls.declaration_Option4"
          title="I understand that this contribution will remain preserved in my account until a condition of release occurs, such as retirement after reaching preservation age or reaching age 65.">
        </app-checkbox>

        <app-checkbox [control]="form.controls.declaration_Option5"
          title="I consent to my member details on file, including my Tax File Number (TFN), being used for the purpose of making personal contributions.">
        </app-checkbox>
      </div>


      <div class="components-actions d-flex flex-row ">

        <app-button loading class="  p-1" title="Save Changes"
          icon="{{'BTN.personal-contribution.save.icon' | translate}}" type="submit" [isDisabled]="form.isInvalid"
          (onClick)="onSubmitClick(form.value)">
        </app-button>

        <app-button loading class="back p-1 " title="Cancel"
          icon="{{'BTN.personal-contribution.cancel.icon' | translate}}" color="light" (onClick)="onConfirmBackClick()">
        </app-button>
      </div>
    </form>
  </div>
  <div *ngIf="(lastModified$ | async)">
    <form [ngrxFormState]="form" *ngIf="form$ | async; let form">
      <p>
        Thank you for submitting your Personal Contribution Form
      </p>
      <div class="d-flex flex-row widgets-row">
        <div class="w-50 p-4">
          <div *ngIf="form.value.paymentsMethods == 0">
            <b>Next Step: Complete the BPAY Deposit</b>
            <div>
              Please use the following payment details when making your contribution via BPAY
              <div class="bpay d-flex flex-column mt-2 mb-2">
                <div class="d-flex flex-row">
                  <div>
                    <img src="https://bpay.com.au/Common/images/bpay-logo.svg">
                  </div>
                  <div class="data">
                    <div class="entry d-flex ">
                      <div style="width: 75px;">Biller Code:</div>
                      <div class="description" [innerHTML]="'personalContributions_BPAY_Code'| message | async"></div>
                    </div>
                    <div class="entry d-flex">
                      <div style="width: 75px;">Ref:</div>
                      <div class="description" [innerHTML]="'personalContributions_BPAY_Ref'| message | async"></div>
                    </div>
                  </div>
                </div>

                <div class="mt-2">
                  <div class="telandint">Telephone & Internet Banking - BPAY</div>
                  <label>Contact your bank or financial institution to make this payment from your cheque, savings,
                    debit, credit
                    card or transaction account. More info <a href="http://www.bpay.com.au">www.bpay.com.au</a></label>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="form.value.paymentsMethods == 1">
            <b>Next Step: Complete the Bank Deposit:</b>
            <div>
              Please use the following payment details when making your non-concessional contribution via direct
              deposit.
              <div class="dd d-flex flex-column mt-2 mb-2">
                <div class="d-flex flex-row">

                  <div class="data" *ngIf="bankDetails$ | async; let bank">
                    <div class="entry d-flex ">
                      <div class="w-50">BSB:</div>
                      <div class="description" [innerHTML]="bank.bsb">
                      </div>
                    </div>
                    <div class="entry d-flex">
                      <div class="w-50">Account Number:</div>
                      <div class="description" [innerHTML]="bank.accountNumber">
                      </div>
                    </div>
                    <div class="entry d-flex">
                      <div class="w-50">Account Name:</div>
                      <div class="description" [innerHTML]="bank.accountName">
                      </div>
                    </div>
                    <div class="entry d-flex">
                      <div class="w-50">Reference:</div>
                      <div class="description">{{ 'PERSONAL_CONTRIBUTION_REFERENCE_PREFIX' | translate }} {{bank.reference}}</div>
                    </div>
                  </div>
                </div>


              </div>




            </div>
          </div>
        </div>
        <div class="w-50 p-4">
          <p class="">Note: Your personal contribution will be allocated to your Verve Super account based on the date
            we receive your payment into our bank account. This generally takes 1-3 business days.</p>

          <app-button loading class="float-right" title="Return to Dashboard" icon="arrow_right"
            routerLink="/dashboard">
          </app-button>
        </div>
      </div>


    </form>
  </div>
</div>
