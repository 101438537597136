
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../../store/cirt.reducers';
import { CIRTState } from '../../../store/cirt.states';
import { IJetcoLodgeClaimState } from './state';

export const memberPortalState = createFeatureSelector<CIRTState>(featureKey);

export const jetcoLodgeClaim = createSelector(memberPortalState, (state) => state.jetcoLodgeClaim);

export const jetcoLodgeClaim_Form = createSelector(
  jetcoLodgeClaim,
  (state: IJetcoLodgeClaimState) => state.form
);

export const jetcoLodgeClaim_claimAmount = createSelector(
  jetcoLodgeClaim,
  (state: IJetcoLodgeClaimState) => state.claimAmount
);

export const jetcoLodgeClaim_lastModifiedTime = createSelector(
  jetcoLodgeClaim,
  (state: IJetcoLodgeClaimState) => state.lastModifiedTime
);
