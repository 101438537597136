import { UIComponentState } from '@ifaa-components/ui-components/lib/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { SMRTState } from '../../store/smrt.states';
import { LodgeClaimModel } from './state';
import { EmployerListRequestAction, MemberDataRequestAction, NextStepAction, PreviousStepAction, RemoveAttachmentByIndexAction, ResetFormAction, SubmitRequestAction, ValidateFormAction } from './actions';
import { lodgeClaim_claimDocumentationList, lodgeClaim_CurrentStep, lodgeClaim_EmployerList, lodgeClaim_Form, lodgeClaim_LastModifiedTime, lodgeClaim_TotalSteps, lodgeClaim_Validated } from './selectors';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { AppState } from 'src/app/store/app.states';


@Component({
  selector: 'app-tenant-lodge-claim',
  templateUrl: './lodge-claim.component.html',
  styleUrls: ['./lodge-claim.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class TenantLodgeClaimComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.smrtstore.pipe(select(lodgeClaim_Form));
  claimDocumentationList$ = this.smrtstore.pipe(select(lodgeClaim_claimDocumentationList));
  employerList$ = this.smrtstore.pipe(select(lodgeClaim_EmployerList));
  currentStep$ = this.smrtstore.pipe(select(lodgeClaim_CurrentStep));
  totalSteps$ = this.smrtstore.pipe(select(lodgeClaim_TotalSteps));
  validated$ = this.store.pipe(select(lodgeClaim_Validated));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  lastModified$ = this.store.pipe(select(lodgeClaim_LastModifiedTime));
  accountId = 0;
  @ViewChild('myTemplate') customTemplate: TemplateRef<any>;
  extensions: string[] = ['.pdf', '.png', '.jpg'];
  maxfilesize = 5242880;//5mb
  constructor(public store: Store<UIComponentState>,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    public smrtstore: Store<SMRTState>,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.store.dispatch(ResetFormAction());

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x && this.accountId === 0) {
        this.accountId = x.accountId;
        this.store.dispatch(MemberDataRequestAction({ accountId: x.accountId }));
        this.dispatch(this.store, EmployerListRequestAction({ accountId: x.accountId }));
      }
    });
    this.sub = this.validated$.subscribe(validated => {
      if (!validated)
        this.dispatch(this.store, ValidateFormAction());
    });

    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.bottomSheet.open(this.customTemplate, {
          disableClose: true
        });

        this.dispatch(this.store, ResetFormAction());
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  trackByFn(index, item) { return index; }

  onSubmitClick(data: LodgeClaimModel) {
    this.dispatch(this.store, SubmitRequestAction({ accountId: this.accountId, payload: data }));
  }

  onRemoveAttachmentClick(index: number) {
    this.dispatch(this.store, RemoveAttachmentByIndexAction({ index }));
  }

  onPreviousClick() {
    this.dispatch(this.store, PreviousStepAction());
  }

  onNextClick() {
    this.dispatch(this.store, NextStepAction());
  }

  onGoToDashboardClick() {
    this.bottomSheet.dismiss();
    this.router.navigate(['/']);
  }
}


