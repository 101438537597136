import { createFeatureSelector, createSelector } from '@ngrx/store';
import { from, of } from 'rxjs';
import { reduce } from 'rxjs/operators';
import { featureKey } from '../../store/shared.reducers';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IBeneficiaryEditState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const beneficiariesEdit = createSelector(memberPortalState, (state) => state.beneficiariesEditState);

export const beneficiaryEdit_Form = createSelector(
  beneficiariesEdit,
  (state: IBeneficiaryEditState) => state.form
);

export const beneficiaryEdit_IsSum100Percent = createSelector(
  beneficiariesEdit,
  (state: IBeneficiaryEditState) => state.form.value.reduce((a, b) => a + b.percentage, 0) == 100
);

export const beneficiaryEdit_CanEdit = createSelector(
  beneficiariesEdit,
  (state: IBeneficiaryEditState) => state.form.value.length == 0 || state.form.value.every(x => x.beneficiaryTypeId == 1)
);

export const beneficiaryEdit_TotalPercent = createSelector(
  beneficiariesEdit,
  (state: IBeneficiaryEditState) => state.form.value.reduce((a, b) => a + b.percentage, 0)
);

export const beneficiaryEdit_LastModified = createSelector(
  beneficiariesEdit,
  (state: IBeneficiaryEditState) => state.lastModifiedTime
);

export const beneficiaryEdit_CanAdd = createSelector(
  beneficiariesEdit,
  (state: IBeneficiaryEditState) => state.maxBeneficiaries == 0 || state.form.value.length < state.maxBeneficiaries
);

export const beneficiaryEdit_MaxBeneficiaries = createSelector(
  beneficiariesEdit,
  (state: IBeneficiaryEditState) => state.maxBeneficiaries
);
