import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction, createFormControlState, createFormGroupState, setValue
} from 'ngrx-forms';
import { ResponseAction } from './actions';
import { IConsolidateSuperState } from './state';

export const cashtransactionsListFilter = 'cashtransactionsListFilter';

export const cashTransactionListState: IConsolidateSuperState = {
  list: [],
};

const reducer = createReducer(cashTransactionListState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      list: payload
    }
  }),
);

export function consolidateSuperReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
