import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IBeneficiaryViewState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getBeneficiariesView = createSelector(memberPortalState, (state) => state.beneficiariesViewState);

export const getBeneficiariesView_List = createSelector(
  getBeneficiariesView,
  (state: IBeneficiaryViewState) => state.list
);

export const beneficiariesView_CanEdit = createSelector(
  getBeneficiariesView,
  (state: IBeneficiaryViewState) => state.list.length == 0 || state.list.every(x => x.beneficiaryTypeId == 1)
);
