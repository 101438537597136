
import { IMemberPensionViewState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { ResetDataAction, ResponseAction, ResponseBeneficiariesAction } from './actions';


export const loginState: IMemberPensionViewState = {
  model: null,
  beneficiaryList: []
};

const reducer = createReducer(loginState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetDataAction, (state) => {
    return {
      ...state,
      model: null,
      beneficiaryList: []
    };
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
  on(ResponseBeneficiariesAction, (state, { payload }) => {
    var list = payload.filter(x=> x.beneficiaryTypeId == 3);
    return {
      ...state,
      beneficiaryList: list
    };
  })
);

export function memberPensionViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
