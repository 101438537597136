import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { OkModel } from 'src/app/model/ok.model';
import { JetcoLodgeClaimModel } from './state';

export const SubmitRequestAction = createAction('[Jetco Lodge Claim] save member drtails',
  props<{ accountId: number, payload: JetcoLodgeClaimModel }>());

export const SubmitResponseAction = createAction('[Jetco Lodge Claim]  member update completed',
  props<{ payload: OkModel<string> }>());


export const JetcoClaimAmountRequestAction = createAction('[Jetco Lodge Claim] get claim amount',
  props<{ start:string, end: string }>());

export const JetcoClaimAmountResponseAction = createAction('[Jetco Lodge Claim]  claim amount completed',
  props<{ payload: number }>());

//   export const RemoveAttachmentByIndexAction = createAction('[Jetco Lodge Claim]  remove attachment by index',
//   props<{ index: number }>());

 export const ResetFormAction = createAction('[Jetco Lodge Claim]  reset form');

