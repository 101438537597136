
export class ContributionDetailModel {
  description: string = '';

  concessionalAmount: number = 0;
  concessionalCap: number = 0;
  concessionalExceeded: boolean = false;

  nonConcessionalAmount: number = 0;
  nonConcessionalCap: number = 0;
  nonConcessionalExceeded: boolean = false;
}
