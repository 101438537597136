import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IInsuranceDetailsState } from './state';
import { MemberPortalSharedState } from '../../store/shared.states';
import { featureKey } from '../../store/shared.reducers';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const insuranceDetails = createSelector(memberPortalState, (state) => state.insuranceDetailsState);

export const insuranceDetails_Model = createSelector(
  insuranceDetails,
  (state: IInsuranceDetailsState) => state.model
);
