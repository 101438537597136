import { featureKey } from './../../store/shared.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IHistoricalChartViewState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const historicalChartView = createSelector(memberPortalState, (state) => state.historicalChartViewState);

export const historicalChartView_Model = createSelector(
  historicalChartView,
  (state: IHistoricalChartViewState) => state.model
);

export const historicalChartView_SelectedFilter = createSelector(
  historicalChartView,
  (state: IHistoricalChartViewState) => state.activeOptionButton
);
