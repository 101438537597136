// import { MemberNotesModel } from './../model/member-notes.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { RollInEditModel } from '../model/rollin-edit.model';

@Injectable()
export class RollInService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  providerSearch(q: string): Observable<any> {
    const params = new HttpParams()
      .set('q', q);
    return this.http.get(environment.apiUrl + `/member-portal/Rollin/provider-search`, { headers: this.headers(), params: params });
  }

  submit(accountId: number, obj: RollInEditModel): Observable<any> {
    return this.http.post<RollInEditModel>(environment.apiUrl + `/member-portal/Rollin/account/${accountId}/submit`, obj, { headers: this.headers() });
  }

  // confirm(accountId: number, obj: RollInEditModel): Observable<any> {
  //   return this.http.post<RollInEditModel>(environment.apiUrl + `/member-portal/Rollin/account/${accountId}/confirm`, obj, { headers: this.headers() });
  // }

  requestedTransfers(accountId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/Rollin/account/${accountId}/requested-transfers`, { headers: this.headers() });
  }
}
