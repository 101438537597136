<app-component-wrapper class="w-50 flex-fill" compclass="flex-fill h-100" id="current-employer-container">
  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="consolidate-super-container">
      <div class="d-flex flex-column">
        <div class="header">
          <!-- <h3>Current Employer</h3> -->
          <!-- <label class="description">Do you have lost super? Do you have multiple super accounts?</label> -->
        </div>
        <div class="bg"></div>

        <!-- <div class="alert alert-info mt-3" *ngIf="(list$ | async).length == 0">
          You have not requested a rollover in the last 30 days
        </div> -->
        <div class="d-flex align-items-center info flex-column ">
          <div class="work">
            <mat-icon>work</mat-icon>
          </div>
          <div *ngIf="employer$ | async; let employer;"class="flex-fill d-flex flex-column align-items-center w-100 pl-2 pr-2">
            <h3 class="title mt-2">{{employer.employerName}}</h3>
            <div class="d-flex flex-row  flex-fill w-100 items">
              <div class="align-items-center d-flex w-100 badge badge-light p-3 m-1 ">
                <div class="d-flex flex-column align-items-center w-100">
                  <mat-icon class="">person</mat-icon>
                  <div class="title">Number</div>
                  <div class="value">{{employer.payrollNumber}}</div>
                </div>
              </div>
              <div class="align-items-center d-flex w-100 badge badge-light p-3 m-1 ">
                <div class="d-flex flex-column align-items-center w-100">
                  <mat-icon class="">event</mat-icon>
                  <div class="title">Joined</div>
                  <div class="value">{{employer.joinDate | date:'dd/MM/yyyy'}}</div>
                </div>
              </div>
              <div class="align-items-center d-flex w-100 badge badge-light p-3 m-1 ">
                <div class="d-flex flex-column align-items-center w-100">
                  <mat-icon class="">event</mat-icon>
                  <div class="title">Last contribution</div>
                  <div class="value">{{employer.lastContribution | date:'dd/MM/yyyy'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>


  <!-- <div class="d-flex flex-row components-actions" slot="actions">
    <app-button icon="{{'BTN.consolidate-super.start.icon' | translate}}"  title="Start" (onClick)="onStartClick()">
    </app-button>
  </div> -->
</app-component-wrapper>
