// import { MemberNotesModel } from './../model/member-notes.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { InsuranceQuestionnaireModel, InsuranceQuestionnaireResultModel } from '../model/insurance-questionnaire.model';

@Injectable()
export class InsuranceQuestionnaireService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  showQuestionnaire(accountId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/InsuranceQuestionnaire/account/${accountId}/show-questionnaire`, { headers: this.headers() });
  }

  submit(accountId: number, obj: InsuranceQuestionnaireModel): Observable<any> {
    // const params = new HttpParams()
    // .set('accountId', accountId.toString());

    return this.http.post<InsuranceQuestionnaireModel>(environment.apiUrl + `/member-portal/InsuranceQuestionnaire/account/${accountId}/submit`, obj, { headers: this.headers()});
  }

  confirm(accountId: number, obj: InsuranceQuestionnaireResultModel): Observable<any> {
    const params = new HttpParams()
    .set('accountId', accountId.toString());

    return this.http.post<InsuranceQuestionnaireResultModel>(environment.apiUrl + `/member-portal/InsuranceQuestionnaire/account/${accountId}/confirm`, obj, { headers: this.headers(), params: params });
  }

}
