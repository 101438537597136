import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { ShowQuestionnaireResultModel } from 'src/app/model/insurance-questionnaire.model';
import { OkModel } from 'src/app/model/ok.model';

export const ShowQuestionnaireRequestAction = createAction('[Insurance Questionnaire view] show questionnaire', props<{ accountId: number }>());
export const ShowQuestionnaireResponseAction = createAction('[Insurance Questionnaire view] show questionnaire response', props<{ payload: ShowQuestionnaireResultModel }>());



