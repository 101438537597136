import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentsService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getDocuments(accountId: number, category: string): Observable<any> {
    switch (category) {
      case 'member-statements':
        return this.http.get(environment.apiUrl + `/member-portal/account/${accountId}/correspondence/member-statements`, { headers: this.headers()});
      case 'other-documents':
        return this.http.get(environment.apiUrl + `/member-portal/account/${accountId}/correspondence/other-documents`, { headers: this.headers()});

    }
    throw new Error('DocumentsService/getDocuments => Type not found')
  }

  getDocument(accountId: number, correspondenceSource: number, correspondenceId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/account/${accountId}/correspondence/${correspondenceId}/file-content`, { headers: this.headers()});
  }

}
