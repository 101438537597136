import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { ShowPreQuestionnaireResultModel } from 'src/app/model/insurance-questionnaire.model';

export const ShowPreQuestionnaireRequestAction = createAction('[Insurance Pre Questionnaire view] show pre questionnaire', props<{ accountId: number }>());
export const ShowPreQuestionnaireResponseAction = createAction('[Insurance Pre Questionnaire view] show pre questionnaire response', props<{ payload: ShowPreQuestionnaireResultModel }>());

export const ShowStartQuestionnaireRequestAction = createAction('[Insurance Pre Questionnaire view] show pre questionnaire', props<{ display: boolean }>());
export const ResetPreQuestionnaireRequestAction = createAction('[Insurance Pre Questionnaire view] reset pre questionnaire');

