import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IInsurancePreQuestionnaireState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const insurancePreQuestionnaire = createSelector(memberPortalState, (state) => state.insurancePreQuestionnaireState);

export const insurancePreQuestionnaire_ShowQuestionnaire = createSelector(
  insurancePreQuestionnaire,
  (state: IInsurancePreQuestionnaireState) => state.showQuestionnaire
);

export const insurancePreQuestionnaire_ShowPreQuestionnaire = createSelector(
  insurancePreQuestionnaire,
  (state: IInsurancePreQuestionnaireState) => state.showPreQuestionnaire
);

export const insurancePreQuestionnaire_ShowStartQuestionnaire = createSelector(
  insurancePreQuestionnaire,
  (state: IInsurancePreQuestionnaireState) => state.showStartQuestionnaire
);

export const insurancePreQuestionnaire_Form = createSelector(
  insurancePreQuestionnaire,
  (state: IInsurancePreQuestionnaireState) => state.form
);

