import { ILodgeClaimState, LodgeClaimModel } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  disable,
  enable,
  formGroupReducer,
  FormGroupState,
  onNgrxForms, onNgrxFormsAction, setErrors, setValue, SetValueAction, updateGroup, validate} from 'ngrx-forms';
import { Helper } from '@ifaa-components/ui-components';
import {  EmployerListResponseAction, MemberDataResponseAction,  NextStepAction, PreviousStepAction, RemoveAttachmentByIndexAction,  ResetFormAction, SubmitResponseAction, ValidateFormAction } from './actions';
import { AppFileModel } from "@ifaa-components/ui-components";
import { required } from 'ngrx-forms/validation';
import { BankDetailsModel } from 'src/app/model/bank-details.model';


export const formName = 'smrtLodgeClaimForm';
var helper = new Helper();

export const lodgeClaimState: ILodgeClaimState = {
  form: createFormGroupState(formName, new LodgeClaimModel()),
  currentStep: 0,
  totalSteps: 1,
  validated: false,
  lastModifiedTime: null,
};

export const validateEditMemberForm0 = updateGroup<LodgeClaimModel>({
  employeeId: validate(required),
  bankDetails: updateGroup<BankDetailsModel>({
    accountName: validate(required),
    bsb: validate(required),
    accountNumber: validate(required),
  }),
  taxFileNumber: (taxFileNumber, myForm) => {
    if (!myForm.value.taxFileNumber && !myForm.value.hasTFN) {
      return setErrors(taxFileNumber, {
        required: {
          actual: taxFileNumber,
        }
      });
    }
    else {
      return setErrors(taxFileNumber, {});
    }
  },
  amountType: (amountType, myForm) => {
    if (myForm.value.amountType === 0 && !myForm.value.employers.some(x => x.claimAmount)) {
      return setErrors(amountType, {
        required: {
          actual: amountType,
        }
      });
    }
    else {
      return setErrors(amountType, {});
    }
  },
  claimType: (claimType, myForm) => {
    if (myForm.value.claimType === 0) {
      return setErrors(claimType, {
        required: {
          actual: claimType,
        }
      });
    }
    else {
      return setErrors(claimType, {});
    }
  }
});

function validateStep(state: ILodgeClaimState, action) {
  let myForm = formGroupReducer(state.form, action);
  let validatedForm = null;

  if (state.currentStep === 0) {
    validatedForm = validateEditMemberForm0(myForm);
  }

  if (validatedForm) {
    return {
      ...state,
      form: validatedForm,
      validated: true
    };
  }
  return state;
}
const reducer = createReducer(lodgeClaimState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    state = validateStep(state, action);
    if (action.controlId.indexOf('attachments') > 0 && state.form.value.attachments[state.form.value.attachments.length - 1].base64) {
      return {
        ...state,
        form: updateGroup<LodgeClaimModel>(state.form, { attachments: setValue([...state.form.value.attachments, new AppFileModel()])})
      };
    }

    return state;
  }),
  on(ValidateFormAction, (state) => {
    var clone = helper.clone(state)
    return validateStep(clone, NextStepAction);

  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date(),
      form: createFormGroupState(formName, new LodgeClaimModel())
    };
  }),
  on(MemberDataResponseAction, (state, { payload }) => {
    return {
      ...state,
      member: payload,
      form: updateGroup<LodgeClaimModel>(state.form, { hasTFN: setValue(payload.hasTfn)})
    };
  }),
  on(EmployerListResponseAction, (state, { payload }) => {
    const form:FormGroupState<LodgeClaimModel> = updateGroup<LodgeClaimModel>(state.form, { employers: setValue(payload)})
    return {
      ...state,
      form: form.value.employers.length > 0 ? enable(form) : disable(form)
    };
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      currentStep: 0,
      lastModifiedTime: null,
      validated: false,
      form: createFormGroupState(formName, new LodgeClaimModel())
    };
  }),
  on(NextStepAction, (state) => {

    return {
      ...state,
      currentStep: state.currentStep + 1,
      validated: false
    };
  }),
  on(PreviousStepAction, (state) => {
    var clone = helper.clone(state.form.value)

    return {
      ...state,
      currentStep: state.currentStep - 1,
      validated: false,
      form: createFormGroupState(formName, clone)
    };
  }),
  on(RemoveAttachmentByIndexAction, (state, { index }) => {
    return {
      ...state,
      form: updateGroup<LodgeClaimModel>(state.form, { attachments: setValue([...state.form.value.attachments.filter((_o, ind) => ind !== index)])})
    };
  })
);

export function lodgeClaimReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
