import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { BeneficiaryModel } from 'src/app/model/beneficiary.model';
import { MemberPensionOverviewModel } from 'src/app/model/pension.model';

export const ResetDataAction = createAction('[Member Pension view] reset');
export const RequestAction = createAction('[Member Pension view] get data',  props<{ accountId: number}>());
export const ResponseAction = createAction('[Member Pension view] set data',  props<{ payload: MemberPensionOverviewModel }>());


export const RequestBeneficiariesAction = createAction('[Member Pension view] get Beneficiaries data',  props<{ accountId: number}>());
export const ResponseBeneficiariesAction = createAction('[Member Pension view] set Beneficiaries data',  props<{ payload: BeneficiaryModel[] }>());



