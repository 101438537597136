import { featureKey } from './../../store/cirt.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IEmployerBalancesState } from './state';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { CIRTState } from '../../store/cirt.states';

export const memberPortalState = createFeatureSelector<CIRTState>(featureKey);

export const employerBalances = createSelector(memberPortalState, (state) => state.employerBalancesState);

export const employerBalances_Data = createSelector(
  employerBalances,
  (state: IEmployerBalancesState) => state.data
);
