import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/base.services';
import { JetcoLodgeClaimModel } from '../views/jetco/lodge-claim/state';

@Injectable()
export class JetcoService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  submit(accountId: number, obj: JetcoLodgeClaimModel): Observable<any> {
    return this.http.post<JetcoLodgeClaimModel>(environment.apiUrl + `/member-portal/TrainingPaymentClaim/${accountId}/submit`, obj, { headers: this.headers() });
  }

  jetcoClaimAmount(start: string, end: string): Observable<any> {
    const params = new HttpParams()
      .set('start', start)
      if(end) {
        params.set('end', end);
      }
    return this.http.get(environment.apiUrl + `/member-portal/TrainingPaymentClaim/jetco-claim-account`, { headers: this.headers(), params: params });
  }
}
