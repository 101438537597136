<div id="verve-academy-container">
  <div class="page-header">
    <app-toolbar-options icon="school">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Verve Academy</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <iframe src="https://vervesuper.com.au/member-login/#" class="w-100"></iframe>

  </div>
</div>
