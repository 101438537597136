import { MemberPortalSharedState } from './../../store/shared.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';

import { IMemberAccountDropdownState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const memberAccountDropdown = createSelector(memberPortalState, (state) => state.memberAccountDropdownState);

export const memberAccountDropdown_Accounts = createSelector(
  memberAccountDropdown,
  (state: IMemberAccountDropdownState) => state.accounts
);

export const memberAccountDropdown_SelectedAccount = createSelector(
  memberAccountDropdown,
  (state: IMemberAccountDropdownState) => state.selectedAccount
);
