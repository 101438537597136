import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { UIComponentState } from '@ifaa-components/ui-components/lib/store/app.states';
import { Title } from "@angular/platform-browser";
import { Meta } from '@angular/platform-browser';
import { AppState } from 'src/app/store/app.states';
@Component({
  selector: 'login-master',
  templateUrl: './not-loggedin.master.html',
  styleUrls: ['./not-loggedin.master.scss']
})
export class NotLoggedInMasterComponent extends ComponentBase {
  bg: string = "";
  constructor(public store: Store<UIComponentState>,
    private titleService: Title,
    public dialog: MatDialog,
    private meta: Meta,
    private router: Router) {
    super();
    this.titleService.setTitle("Smart Monday – Member Portal");
    this.meta.addTag({ name: 'description', content: 'Smart Monday Member Portal. Log in to access your super account details, super balance and change your details.' });
    this.meta.addTag({ name: 'keywords', content: 'Smart Monday, Smart Monday Member Portal, Smart Monday Login' });
  }

  ngOnInit() {
    super.ngOnInitBase();

    var list = [
      'https://as2.ftcdn.net/v2/jpg/04/22/33/97/1000_F_422339759_dX0ZCAhPlzgbTR5jO1lJ9avu1UGhY2L1.jpg',
      'https://as2.ftcdn.net/v2/jpg/04/73/14/93/1000_F_473149388_Lmaa23qUD8WXvywJHdwO5oIr9heu6uXZ.jpg',
      'https://as1.ftcdn.net/v2/jpg/03/90/12/42/1000_F_390124234_aUpkLP5zVGAoYLIYYM9dIDq0cJAWHPHi.jpg',
      'https://as1.ftcdn.net/v2/jpg/04/43/87/80/1000_F_443878003_b906ywdMm2N4fPBnhA3vxp74llnWQwPE.jpg',
      'https://as1.ftcdn.net/v2/jpg/04/01/01/48/1000_F_401014880_2Q1pcdLY4KRQUHwBdYgaDhlb4Vsp3PNv.jpg',
      'https://as2.ftcdn.net/v2/jpg/02/96/45/65/1000_F_296456565_LyHIBM4QdbExDQHZiotNJfEQLMk1tbG4.jpg',
    ]
    this.bg = list[Math.floor(Math.random() * list.length)]

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
