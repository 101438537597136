// import { CostOfCoverModel } from './../model/cost-of-cover.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class CostOfCoverService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getCostOfCovers(): Observable<any> {
    return this.http.get(environment.apiUrl + `/fund/costofcovers`, { headers: this.headers() });
  }

  // update(obj: CostOfCoverModel): Observable<any> {
  //   return this.http.put<CostOfCoverModel>(environment.apiUrl + `/fund/${obj.costOfCoverId}/updatecostofcover`, obj, { headers: this.headers() });
  // }
}
