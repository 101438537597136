import { BeneficiaryModel } from './../../../../model/beneficiary.model';

import { IBeneficiaryEditState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  addArrayControl,
  createFormArrayState,
  createFormControlState,
  disable,
  DisableAction,
  enable,
  EnableAction,
  formStateReducer,
  markAsDirty,
  MarkAsDirtyAction,
  MarkAsTouchedAction,
  onNgrxForms, onNgrxFormsAction, removeArrayControl, setValue, SetValueAction, updateArray, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { AddNewBeneficiaryAction, ResetAction, RemoveBeneficiaryAction, RequestAction, ResponseAction, SubmitResponseAction, SetupMaxBeneficiariesAction } from './actions';
import { greaterThan, greaterThanOrEqualTo, lessThan, lessThanOrEqualTo, number, required } from 'ngrx-forms/validation';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'beneficiaryEditForm';

var helper = new Helper();

export const state: IBeneficiaryEditState = {
  form: createFormArrayState(formName, []),
  lastModifiedTime: null,
  maxBeneficiaries: 0
};

export const validateForm = updateArray(updateGroup<BeneficiaryModel>({
  name: validate(required),
  relationship: validate(required),
  percentage: validate(required, greaterThan(0), lessThanOrEqualTo(100)),
}));

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    // if (action.controlId.indexOf('percentage') > 0) {
    //   var clone = helper.clone(state.form.value);
    //   var split = action.controlId.split('.');
    //   clone[parseInt(split[1])].percentage = parseFloat(action.value.toString());
    //   return {
    //     ...state,
    //     form: setValue(clone)(state.form),
    //   };
    // }
    return state;
  }),
  on(ResetAction, (state) => {
    return {
      ...state,
      form: createFormArrayState(formName, []),
      lastModifiedTime: null
    };
  }),
  on(SetupMaxBeneficiariesAction, (state, { value }) => {
    return {
      ...state,
      maxBeneficiaries: value
    };
  }),
  on(RequestAction, (state) => {
    return {
      ...state,
      form: setValue([])(state.form),
      lastModifiedTime: null
    };
  }),
  on(AddNewBeneficiaryAction, (state) => {

    var b = new BeneficiaryModel();
    b.beneficiaryTypeId = 1;

    var newForm = addArrayControl(state.form, b)
    return {
      ...state,
      form: newForm
    };
  }),
  on(RemoveBeneficiaryAction, (state, { index }) => {
    var newForm = removeArrayControl(state.form, index);
    return {
      ...state,
      form: newForm
    };
  }),
  on(ResponseAction, (state, { payload }) => {
    var newForm = (setValue(payload)(state.form));

    newForm.controls.map(control => {
      if (!control.value.editable) {
        newForm = formStateReducer(newForm, new DisableAction(control.id));
      }
      else {
        newForm = formStateReducer(newForm, new EnableAction(control.id));
      }
    });

    return {
      ...state,
      form: newForm,
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),
);

export const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateForm,
);

export function beneficiariesEditReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
