import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue
} from 'ngrx-forms';
import { required, email } from 'ngrx-forms/validation';
import { ClearSearchAction, RequestAction, ResponseAction, SelectOptionAction, ResetAction } from './actions';
import { ISuperannuationProviderSearchState, SuperannuationProviderSearchModel } from './state';
import { Helper } from '@ifaa-components/ui-components'
export const formName = 'SuperannuationProviderSearchEditForm';

var helper = new Helper();
export const state: ISuperannuationProviderSearchState = {
  form: createFormGroupState(formName, new SuperannuationProviderSearchModel()),
  lastModifiedTime: null
};

export const validateEditMemberForm = updateGroup<SuperannuationProviderSearchModel>({
  // firstName: validate(required),
  // lastName: validate(required),
  //email: validate(required, email),
  // dateOfBirth: validate(required),
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetAction, (state) => {
    return {
      form: createFormGroupState(formName, new SuperannuationProviderSearchModel()),
      lastModifiedTime: null
    };
  }),
  on(RequestAction, (state, { q }) => {

    if (!q) {
      var clone = helper.clone(state.form.value);
      clone.list = [];
      return {
        ...state,
        form: setValue(clone)(state.form),
      };
    }
    else {
      return {
        ...state,
      };
    }

  }),
  on(ResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.list = payload;
    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(SelectOptionAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.selected = payload;
    clone.q = '';
    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(ClearSearchAction, (state) => {
    var clone = helper.clone(state.form.value);
    clone.q = '';
    clone.list = [];
    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),

);

const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateEditMemberForm,
);

export function superannuationProviderSearchReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
