import { AppFileModel } from "@ifaa-components/ui-components";

import { FormGroupState } from "ngrx-forms";
import { BankDetailsModel } from '../../../../model/bank-details.model';

export interface ILodgeClaimState {
  form: FormGroupState<LodgeClaimModel>;
  currentStep: number;
  totalSteps: number;
  validated: boolean;
  lastModifiedTime: Date;
}

export class LodgeClaimModel {
  employeeId: number = 0;
  taxFileNumber: string = '';
  hasTFN: boolean = false;
  amountType: number = 0;
  claimType: number = 0;
  attachments: AppFileModel[] = [new AppFileModel()];
  bankDetails: BankDetailsModel = new BankDetailsModel();
  employers: SMRTEmployerModel[] = [new SMRTEmployerModel()];
  waitForFinalContributions: boolean = true;
  workingHolidayMakerVisa: boolean = false;
}

export class SMRTEmployerModel {
  employmentId: number = 0;
  employerId: number = 0;
  startDate: string = '';
  amount: number = 0;
  claimAmount: boolean = false;
}

export class MemberTfnResultModel {
  hasTfn: boolean = false;
}
