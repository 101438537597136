import { TransactionDetailModel } from 'src/app/model/transaction-detail.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { KeyValueModel } from '@ifaa-components/ui-components'

export const CashTransactionsSetFromToDateAction = createAction('[Cash transaction list] set  from to dates',
  props<{ from: string, to: string }>());

export const CashTransactionsRequestAction = createAction('[Cash transaction list] get transaction',
  props<{ accountId: number, page: number, filter: string }>());

export const CashTransactionsResponseAction = createAction('[Cash transaction list] set transaction',
  props<{ payload: TransactionDetailModel[] }>());


export const CashTransactionsTypesRequestAction = createAction('[Cash transaction list] get transaction types',
  props<{ accountId: number }>());

export const CashTransactionsTypesResponseAction = createAction('[Cash transaction list] set transaction types',
  props<{ payload: KeyValueModel[] }>());

  export const CashTransactionsDescriptionRequestAction = createAction('[Cash transaction list] get transaction description',
  props<{ accountId: number }>());

export const CashTransactionsDescriptionResponseAction = createAction('[Cash transaction list] set transaction description',
  props<{ payload: KeyValueModel[] }>());


export const CashTransactionIncrementPageAction = createAction('[Cash transaction list] transaction increment page');

export const ResetFormAction = createAction('[Cash transaction list] reset form');
