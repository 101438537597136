import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { MemberCorrespondenceSummaryModel } from 'src/app/model/member-correspondence-summary.model';


export const RequestAction = createAction('[Document Viewer list] get ',
  props<{ accountId: number, category: string }>());

export const ResponseAction = createAction('[Document Viewer list] set ',
  props<{ payload: MemberCorrespondenceSummaryModel[] }>());

export const FileRequestAction = createAction('[Document Viewer list] get file ',
  props<{ accountId: number, correspondenceSource: number, correspondenceId: number }>());

export const ResetFileAction = createAction('[Document Viewer list] reset selected file');
