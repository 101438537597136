import { AddressModel } from "@ifaa-components/ui-components";

export class PersonalContributionModel {
  eligibilityToContribute: number = 0;
  contributeFrequency: number = 0;
  personalContribution: number = 0;
  bankPayments: number = 0;
  paymentsMethods: number = 1;
  declaration_Option1: boolean = false;
  declaration_Option2: boolean = false;
  declaration_Option3: boolean = false;
  declaration_Option4: boolean = false;
  declaration_Option5: boolean = false;
}
