import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { CIRTState } from '../../../store/cirt.states';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { currentEmployer_Employer } from './selectors';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { RequestAction } from './actions';

@Component({
  selector: 'app-current-employer-view',
  templateUrl: './current-employer-view.component.html',
  styleUrls: ['./current-employer-view.component.scss']
})
export class CurrentEmployerViewComponent extends ComponentBase implements OnInit, OnDestroy {
  employer$ = this.cirtstore.pipe(select(currentEmployer_Employer));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  constructor(public store: Store<MemberPortalSharedState>,
    public cirtstore: Store<CIRTState>,
  ) {
    super();
  }


  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      console.warn(x);
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    });

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}

