<div id="forgot-password-container" class="d-flex ">
  <div class="d-flex w-100">
    <div class="left-side">


    </div>
    <div class="right-side flex-fill d-flex align-items-center">
      Loading...

    </div>
  </div>
