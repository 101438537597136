import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { RequestedTransferModel } from '../../../../model/requested-transfer.model';

export const RequestAction = createAction('[Consolidate super] get ',
  props<{ accountId: number }>());

export const ResponseAction = createAction('[Consolidate super] set ',
  props<{ payload: RequestedTransferModel[] }>());

