import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const memberPortalSharedRoutes: Routes = [

];

@NgModule({
  imports: [RouterModule.forChild(memberPortalSharedRoutes)],
  exports: [RouterModule]
})
export class MemberPortalSharedRoutingModule {}
