
import { IHistoricalChartViewState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { ResponseAction, SelectFilterAction } from './actions';
import { AccountUnderlyingModel } from '../../../../model/account-underlying.model';
import { HistoricalGraphModel } from 'src/app/model/historical-graph.model';

export const state: IHistoricalChartViewState = {
  model: new HistoricalGraphModel(),
  activeOptionButton: 'all'
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      model: payload
    };
  }),
  on(SelectFilterAction, (state, { payload }) => {
    return {
      ...state,
      activeOptionButton: payload
    };
  })
);

export function historicalChartViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
