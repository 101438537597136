import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { OkModel } from 'src/app/model/ok.model';
import { SMRTEmployerModel, LodgeClaimModel, MemberTfnResultModel } from './state';

export const SubmitRequestAction = createAction('[Lodge Claim] save member details',
  props<{ accountId: number, payload: LodgeClaimModel }>());

export const SubmitResponseAction = createAction('[Lodge Claim]  member update completed',
  props<{ payload: OkModel<string> }>());

export const RemoveAttachmentByIndexAction = createAction('[Lodge Claim]  remove attachment by index',
  props<{ index: number }>());

export const ResetFormAction = createAction('[Lodge Claim]  reset');
export const NextStepAction = createAction('[Lodge Claim] next');
export const PreviousStepAction = createAction('[Lodge Claim] previous');

export const EmployerListRequestAction = createAction('[Lodge Claim] EmployerList request',
  props<{ accountId: number }>());

export const EmployerListResponseAction = createAction('[Lodge Claim] EmployerList response',
  props<{ payload: SMRTEmployerModel[] }>());

export const MemberDataRequestAction = createAction('[Lodge Claim] MemberData request', props<{ accountId: number }>());
export const MemberDataResponseAction = createAction('[Lodge Claim]  MemberData request completed',
  props<{ payload: MemberTfnResultModel }>());

export const ValidateFormAction = createAction('[Lodge Claim] validate form');
