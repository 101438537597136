import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IDocumentViewerListState } from './state';
import { MemberPortalSharedState } from '../../store/shared.states';
import { featureKey } from './../../store/shared.reducers';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const documentViewerList = createSelector(memberPortalState, (state) => state.documentViewerListState);

export const documentViewer_List = createSelector(
  documentViewerList,
  (state: IDocumentViewerListState) => state.list
);

export const documentViewer_SelectedFile = createSelector(
  documentViewerList,
  (state: IDocumentViewerListState) => state.selectedFile
);
