// import { commomState_LoggedinInfo } from './../store/common/common.selectors';
import { AppState } from './../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { of, Observable } from 'rxjs';

@Injectable()
export class PermissionGuardService implements CanActivate {

  // loggeinInInfo$ = this.store.pipe(select(commomState_LoggedinInfo));

  constructor(public store: Store<AppState>,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let role = route.data.permission as string;
    return new Observable<boolean>(obs => {
      // this.loggeinInInfo$.subscribe(x => {
      //   if (!x.permission) return;
      //   var found = x.permission.filter(x => {
      //     if (role.toLowerCase() == x.toLowerCase() || x.toLowerCase() == 'super user')
      //       return x;
      //   });

        // if (found.length == 0) {
        //   this.router.navigate(['/dashboard']);
        //   obs.next(false);
        // }

      //   obs.next(true);
      // })
    });
  }
}
