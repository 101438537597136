import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState, onNgrxForms, updateGroup, validate, wrapReducerWithFormStateUpdate,
  onNgrxFormsAction, SetValueAction, setValue, formStateReducer, ResetAction, reset
} from 'ngrx-forms';
import { requiredTrue, greaterThan } from 'ngrx-forms/validation';
import { IPersonalContributionEditState } from './state';
import { PersonalContributionModel } from 'src/app/model/personal-contribution.model';
import { BankDetailsResponseAction, ResetFormAction, SetDefaultPaymentMethodAction, SubmitResponseAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'PersonalContributionEditForm';
var helper = new Helper();

export const state: IPersonalContributionEditState = {
  form: createFormGroupState(formName, new PersonalContributionModel()),
  lastModifiedTime: null,
  bankDetails: null
};

export const validateForm = updateGroup<PersonalContributionModel>({
  declaration_Option1: validate(requiredTrue),
  declaration_Option2: validate(requiredTrue),
  declaration_Option3: validate(requiredTrue),
  declaration_Option4: validate(requiredTrue),
  declaration_Option5: validate(requiredTrue),
  personalContribution: validate(greaterThan(0))
});

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    // if (action.controlId.indexOf('personalContribution') > 0) {
    //   var clone = helper.clone(state.form.value);
    //   clone.personalContribution = parseFloat(action.value.toString());
    //   return {
    //     ...state,
    //     form: setValue(clone)(state.form),
    //   };
    // }
    return state;
  }),
  on(SetDefaultPaymentMethodAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.paymentsMethods = payload;
    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(BankDetailsResponseAction, (state, { payload }) => {
    return {
      ...state,
      bankDetails: payload
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date()
    };
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      form: createFormGroupState(formName, new PersonalContributionModel()),
      lastModifiedTime: null
    };
  }),
);

const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateForm,
);

export function personalContributionEditReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
