import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
// import { RoleModel } from '../model/role.model';

@Injectable()
export class RolesService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }


  get(search: string): Observable<any> {
    const params = new HttpParams()
      .set('search', search);
    return this.http.get(environment.apiUrl + '/roles/search', { headers: this.headers(), params: params });
  }

  getById(roleId: string): Observable<any> {
    return this.http.get(environment.apiUrl + `/roles/${roleId}`, { headers: this.headers() });
  }

  // update(obj: RoleModel): Observable<any> {
  //   return this.http.put<RoleModel>(environment.apiUrl + `/roles/${obj.id}`, obj, { headers: this.headers() });
  // }

  // create(obj: RoleModel): Observable<any> {
  //   return this.http.post<RoleModel>(environment.apiUrl + `/roles/`, obj, { headers: this.headers() });
  // }
}
