import { IJetcoLodgeClaimState, JetcoLodgeClaimModel } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction, updateGroup, validate, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { Helper } from '@ifaa-components/ui-components';
import { JetcoClaimAmountResponseAction, ResetFormAction, SubmitResponseAction } from './actions';
import { greaterThan, required, requiredTrue } from 'ngrx-forms/validation';
import { BankDetailsModel } from 'src/app/model/bank-details.model';


export const formName = 'cirtJetcoLodgeClaimForm';
var helper = new Helper();

export const jetcoLodgeClaimState: IJetcoLodgeClaimState = {
  form: createFormGroupState(formName, new JetcoLodgeClaimModel()),
  claimAmount: 0,
  lastModifiedTime: null
};

export const validateForm = updateGroup<JetcoLodgeClaimModel>({
  trainingCourse: validate(required),
  trainingProvider: validate(required),
  startDate: validate(required),
  invoiceAndReceipt: validate(required),
  completionCertificate: validate(required),
  endDate: validate(required),
  costOfTraining: validate(required, greaterThan(0)),
  declaration: validate(requiredTrue),
  bankDetails: updateGroup<BankDetailsModel>({
    accountName: validate(required),
    accountNumber: validate(required),
    bsb: validate(required),
  }),
});

const reducer = createReducer(jetcoLodgeClaimState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    // if (action.controlId.indexOf('attachments') > 0 && state.form.value.attachments[state.form.value.attachments.length - 1].base64) {
    //   var clone = helper.clone(state.form.value);
    //   clone.attachments.push(new AppFileModel());
    //   return {
    //     ...state,
    //     form: setValue(clone)(state.form),
    //   };
    // }
    return state;
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      form: createFormGroupState(formName, new JetcoLodgeClaimModel()), //setValue(new CertificateOfEmploymentModel())(state.form),
      lastModifiedTime: null
    };
  }),
  on(SubmitResponseAction, (state, { payload }) => {
    return {
      ...state,
      lastModifiedTime: new Date(),
      form: createFormGroupState(formName, new JetcoLodgeClaimModel())
    };
  }),
  on(JetcoClaimAmountResponseAction, (state, { payload }) => {
    return {
      ...state,
      claimAmount: payload
    };
  }),
  // on(AddAttachmentAction, (state) => {
  //   var clone = helper.clone(state.form.value);
  //   clone.attachments.push(new AppFileModel());
  //   return {
  //     ...state,
  //     form: setValue(clone)(state.form),
  //   };
  // }),
  // on(RemoveAttachmentByIndexAction, (state, { index }) => {
  //   var clone = helper.clone(state.form.value);
  //   clone.attachments.splice(index, 1);
  //   return {
  //     ...state,
  //     form: setValue(clone)(state.form),
  //   };
  // })

);


const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateForm,
);


export function jetcoLodgeClaimReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
