import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { jetcoClaimAmount_Amount } from './selectors';
import { Helper } from '@ifaa-components/ui-components';
import { CIRTState } from '../../store/cirt.states';


@Component({
  selector: 'app-jetco-claim-amount',
  templateUrl: './jetco-claim-amount.component.html',
  styleUrls: ['./jetco-claim-amount.component.scss']
})
export class JetcoClaimComponent extends ComponentBase implements OnInit, OnDestroy {

  amount$ = this.cirtstore.pipe(select(jetcoClaimAmount_Amount));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  fy: string = '';

  constructor(public store: Store<MemberPortalSharedState>,
    public cirtstore: Store<CIRTState>,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    var helper = new Helper();
    var fy = helper.getFinancialYear(new Date());
    this.fy = fy.from.getFullYear() + '/' + fy.to.getFullYear();

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ start: fy.from.toISOString(), end: fy.to.toISOString() }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}

