<!-- <app-component-wrapper class="w-50 " compclass=" h-100" id="jetco-claim-amount-container" >
  <div class="d-flex flex-column flex-fill" slot="start">
    <div class="pt-2">
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center info flex-row ">
          <div class="flex-fill">
            <h3 class="title mb-0">Jetco Claim Amount</h3>
            <div class="sub-title ">{{fy}}</div>
          </div>
          <div class="align-items-center d-flex">

            <mat-icon class="mr-2">paid</mat-icon>
            {{amount$ | async | currency}}
          </div>
        </div>
      </div>
    </div>
  </div>

</app-component-wrapper> -->



<app-component-wrapper class="w-50 flex-fill" compclass="flex-fill h-100" id="jetco-claim-amount-container">
  <div class="d-flex flex-column flex-fill" slot="start">
    <div id="consolidate-super-container">
      <div class="d-flex flex-column">
        <div class="header">
                 </div>
        <div class="bg"></div>

        <div class="d-flex align-items-center info flex-column ">
          <div class="work">
            <mat-icon>work</mat-icon>
          </div>
          <div class="flex-fill d-flex flex-column align-items-center">
            <h3 class="title mt-2 mb-0">Jetco Claim Amount</h3>
            <div class="sub-title mb-3">{{fy}}</div>

            <div class="align-items-center d-flex mb-2 amount">
              <!-- <mat-icon class="mr-2">paid</mat-icon> -->
              {{amount$ | async | currency}}
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>


</app-component-wrapper>
