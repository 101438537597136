// import { MemberNotesModel } from './../model/member-notes.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { MemberModel } from '../model/member.model';
import { PersonalContributionModel } from '../model/personal-contribution.model';

@Injectable()
export class PersonalContributionService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  submitData(accountId: number, obj: PersonalContributionModel): Observable<any> {
    return this.http.post<PersonalContributionModel>(environment.apiUrl + `/member-portal/PersonalContributions/account/${accountId}`, obj, { headers: this.headers() });
  }


  bankDetails(): Observable<any> {
    return this.http.get(environment.apiUrl + '/member-portal/PersonalContributions/bank-details', { headers: this.headers() });
  }


}
