import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { mainLayoutReducer } from '../layout/main/reducer'
import { VerveState } from './verve.states';

export const featureKey = 'verv';

export const reducers = new InjectionToken<ActionReducerMap<VerveState>>(featureKey, {
  factory: () => ({
    mainLayout: mainLayoutReducer
  })
});
