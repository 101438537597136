import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/smrt.reducers';
import { SMRTState } from '../../store/smrt.states';

import { IEmployerHistoryViewState } from './state';

export const memberPortalState = createFeatureSelector<SMRTState>(featureKey);

export const getEmployerHistoryView = createSelector(memberPortalState, (state) => state.employerHistoryViewState);

export const getBeneficiariesView_List = createSelector(
  getEmployerHistoryView,
  (state: IEmployerHistoryViewState) => state.list
);

