import { MemberModel } from './../../../../model/member.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { InsuranceDetailsModel } from 'src/app/model/insurance-details.model';

export const RequestAction = createAction('[Insurance details] get data', props<{ accountId: number }>());
export const ResponseAction = createAction('[Insurance details] set data', props<{ payload: InsuranceDetailsModel }>());



