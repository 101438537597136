import { IMainLayoutState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { MainExpandedRequestAction, MainSetAnimatedPageAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

export const mainLayoutState: IMainLayoutState = {
  page: 0,
  expanded: [],
  animated: false
};
var helper = new Helper();

const reducer = createReducer(mainLayoutState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(MainSetAnimatedPageAction, (state) => {
    return {
      ...state,
      animated: true
    };
  }),
  on(MainExpandedRequestAction, (state, { menuItem }) => {
    var clone = helper.clone(state);

    var expanded = clone.expanded.filter(x => x.id == menuItem.id);

    if (expanded.length == 0) {
      clone.expanded = [menuItem];
    }
    else {
      clone.expanded = clone.expanded.filter(x => x.id !== menuItem.id);
    }

    return {
      ...state,
      expanded: clone.expanded
    };
  })

);

export function mainLayoutReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
