<div id="account-balance-view-container">

  <div class="d-flex widgets-row ">
    <div class="widget widget1 w-40" *ngIf="model$ | async; let item">
      <app-readonly title="Account Balance" [value]="item.estimatedAccountBalance"></app-readonly>
      <app-readonly title="Investment Option" [value]="item.currentInvestmentOption"></app-readonly>
      <app-readonly title="Number of Units" [value]="item.numberOfUnits"></app-readonly>
      <app-readonly title="Current Unit Price" [value]="item.currentUnitPrice"></app-readonly>
      <app-readonly title="As at" [value]="item.asAt | date:'dd/MM/yyyy'"></app-readonly>
    </div>
    <div class="widget widget2 w-60" style="position: relative;">
      <apx-chart #chart1 [series]="chartOptions.series" [chart]="chartOptions.chart" [tooltip]="chartOptions.tooltip"
      [yaxis]="chartOptions.yaxis" [states]="chartOptions.states"
        [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions" [xaxis]="chartOptions.xaxis">
      </apx-chart>

    </div>
  </div>

</div>
