import { featureKey } from './../../store/shared.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IAccountUnderlyingViewState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccountUnderlyingView = createSelector(memberPortalState, (state) => state.accountUnderlyingViewState);

export const getAccountUnderlyingView_Model = createSelector(
  getAccountUnderlyingView,
  (state: IAccountUnderlyingViewState) => state.model
);
