import { featureKey } from './../../store/verve.reducers';
import { VerveState } from './../../store/verve.states';
import { AppState } from './../../../../store/app.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMainLayoutState } from './state';

export const memberPortalState = createFeatureSelector<VerveState>(featureKey);

export const mainLayout = createSelector(memberPortalState, (state) => state.mainLayout);


export const mainLayout_Expanded = createSelector(
  mainLayout,
  (state: IMainLayoutState) => state.expanded
);

// export const mainSearchMembers_CurrentPage = createSelector(
//   mainSearchMembers,
//   (state: IMainSearchMemberState) => state.page
// );

// export const mainSearchMembers_CurrentElevatedAccess = createSelector(
//   mainSearchMembers,
//   (state: IMainSearchMemberState) => state.currentElevatedAccess
// );

// export const mainSearchMembers_Form = createSelector(
//   mainSearchMembers,
//   (state: IMainSearchMemberState) => state.form
// );

// export const mainSearchMembers_Form_Q = createSelector(
//   mainSearchMembers,
//   (state: IMainSearchMemberState) => state.form.value.q
// );
