import { featureKey } from './../../store/cirt.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IJetcoClaimAmountState } from './state';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { CIRTState } from '../../store/cirt.states';

export const memberPortalState = createFeatureSelector<CIRTState>(featureKey);

export const jetcoClaimAmount = createSelector(memberPortalState, (state) => state.jetcoClaimAmountState);

export const jetcoClaimAmount_Amount = createSelector(
  jetcoClaimAmount,
  (state: IJetcoClaimAmountState) => state.amount
);
