import { BankDetailsRequestAction, ResetFormAction, SetDefaultPaymentMethodAction, SubmitRequestAction } from './actions';
import { MemberModel } from './../../../../model/member.model';
import { editMember_BankDetails, editMember_Form, editMember_LastModified } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { PersonalContributionModel } from 'src/app/model/personal-contribution.model';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { AppState } from 'src/app/store/app.states';
import { commomState_PersonalContributions_PaymentsMethods } from 'src/app/store/common/common.selectors';

@Component({
  selector: 'app-personal-contribution-edit',
  templateUrl: './personal-contribution-edit.component.html',
  styleUrls: ['./personal-contribution-edit.component.scss']
})
export class PersonalContributionEditComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.store.pipe(select(editMember_Form));
  lastModified$ = this.store.pipe(select(editMember_LastModified));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  paymentsMethods$ = this.appstore.pipe(select(commomState_PersonalContributions_PaymentsMethods));
  bankDetails$ = this.appstore.pipe(select(editMember_BankDetails));
  selectedAccount: number;
  constructor(public store: Store<MemberPortalSharedState>, public appstore: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, ResetFormAction());
    this.dispatch(this.store, BankDetailsRequestAction());
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.selectedAccount = x.accountId;
      }
    });

    this.sub = this.paymentsMethods$.subscribe(x => {
      if (x && x.length == 1) {
        this.dispatch(this.appstore, SetDefaultPaymentMethodAction({ payload: parseInt(x[0].key.toString()) }))
      }
    });

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSubmitClick(data: PersonalContributionModel) {
    this.dispatch(this.store, SubmitRequestAction({ accountId: this.selectedAccount, payload: data }));
  }

  onConfirmBackClick() {
    this.router.navigate(['/dashboard']);
  }

  checkIsDeclatarionCompleted(data: PersonalContributionModel) {
    return data.declaration_Option1
      && data.declaration_Option2
      && data.declaration_Option3
      && data.declaration_Option4;
  }

}


