import { BeneficiaryEditModel, BeneficiaryModel } from './../../../../model/beneficiary.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { OkModel } from 'src/app/model/ok.model';

export const AddNewBeneficiaryAction = createAction('[Beneficiaries edit] add new beneficiary');
export const RemoveBeneficiaryAction = createAction('[Beneficiaries edit] remove beneficiary', props<{ index: number }>());

export const ResetAction = createAction('[Beneficiaries edit] reset');

export const RequestAction = createAction('[Beneficiaries edit] get data', props<{ accountId: number }>());
export const ResponseAction = createAction('[Beneficiaries edit] set data', props<{ payload: BeneficiaryModel[] }>());

export const SubmitRequestAction = createAction('[Beneficiaries edit] save Beneficiaries dEtails',
  props<{ accountId: number, payload: BeneficiaryModel[] }>());

export const SubmitResponseAction = createAction('[Beneficiaries edit]  Beneficiaries update completed',
  props<{ payload: OkModel<string> }>());

  export const SetupMaxBeneficiariesAction = createAction('[Beneficiaries edit]  update max beneficiaries',
  props<{ value: number }>());

