import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IInsuranceQuestionnaireState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const insuranceQuestionnaire = createSelector(memberPortalState, (state) => state.insuranceQuestionnaireState);

export const insuranceQuestionnaire_ShowQuestionnaire = createSelector(
  insuranceQuestionnaire,
  (state: IInsuranceQuestionnaireState) => state.showQuestionnaire
);

export const insuranceQuestionnaire_AgeNextBirthday = createSelector(
  insuranceQuestionnaire,
  (state: IInsuranceQuestionnaireState) => state.ageNextBirthday
);

export const insuranceQuestionnaire_ElegibilityDate = createSelector(
  insuranceQuestionnaire,
  (state: IInsuranceQuestionnaireState) => state.elegibilityDate
);

export const insuranceQuestionnaire_Form = createSelector(
  insuranceQuestionnaire,
  (state: IInsuranceQuestionnaireState) => state.form
);

