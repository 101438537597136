export class BeneficiaryModel {
  beneficiaryId: number = 0;
  name: string = '';
  beneficiaryTypeId: number = 0;
  beneficiaryTypeName: string = 'Non-Binding';
  percentage: number = 0;
  relationship: string = '';
  expirationDate: Date = null;
  editable: boolean = true;
}

export class BeneficiaryTypeModel {
  beneficiaryTypeId: number = 0;
  name: string = '';
}

export class BeneficiaryEditModel {
  list: BeneficiaryModel[] = [];
}
