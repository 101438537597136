
import { IBPAYDetailsViewState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { ResponseAction } from './actions';
import { BPAYDetailsModel } from 'src/app/model/bpay-details.model';

export const bpayDetailsViewState: IBPAYDetailsViewState = {
  item: undefined
};

const reducer = createReducer(bpayDetailsViewState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      item: payload
    };
  })
);

export function bpayDetailsViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
