import { getBeneficiariesView_List } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { Router } from '@angular/router';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { SMRTState } from '../../store/smrt.states';

@Component({
  selector: 'app-employment-history-view',
  templateUrl: './employment-history-view.component.html',
  styleUrls: ['./employment-history-view.component.scss']
})
export class EmploymentHistoryViewComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  list$ = this.store.pipe(select(getBeneficiariesView_List));

  constructor(public store: Store<MemberPortalSharedState>,
    public smrtstore: Store<SMRTState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }



  onEditClick() {
    // this.router.navigate(['beneficiaries-edit']);

  }
}
