import { OkModel } from './../../../../model/ok.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { MemberModel } from 'src/app/model/member.model';
import { PersonalContributionModel } from 'src/app/model/personal-contribution.model';
import { BankDetailsModel } from 'src/app/model/bank-details.model';

export const ResetFormAction = createAction('[Personal Contribution Edit] reset form');

export const SubmitRequestAction = createAction('[Personal Contribution Edit] submit',
  props<{ accountId: number, payload: PersonalContributionModel }>());
export const SubmitResponseAction = createAction('[Personal Contribution Edit]  submit response',
  props<{ payload: OkModel<string> }>());


export const SetDefaultPaymentMethodAction = createAction('[Personal Contribution Edit]  set default pymemt method',
  props<{ payload: number }>());



export const BankDetailsRequestAction = createAction('[Personal Contribution Edit] BankDetails request');
export const BankDetailsResponseAction = createAction('[Personal Contribution Edit]  BankDetails response',
  props<{ payload: BankDetailsModel }>());
