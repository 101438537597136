import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { employerBalances_Data } from './selectors';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { CIRTState } from '../../store/cirt.states';
import { MemberPortalSharedState } from 'src/app/modules/shared/store/shared.states';

@Component({
  selector: 'app-employer-balances',
  templateUrl: './employer-balances.component.html',
  styleUrls: ['./employer-balances.component.scss']
})
export class EmployerBalancesComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.cirtstore.pipe(select(employerBalances_Data));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  constructor(public store: Store<MemberPortalSharedState>,
    public cirtstore: Store<CIRTState>,
  ) {
    super();
  }


  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
