export enum CommunicationPreference {
    unknown = -1, 
    byEmail = 4, 
    byPost = 1
  }

  export const CommunicationPreferenceDescription: { [key in CommunicationPreference]: string } = {
    [CommunicationPreference.unknown]: "Unknown",
    [CommunicationPreference.byEmail]: "By Email",
    [CommunicationPreference.byPost]: "By Post",
  };