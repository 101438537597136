import { AppState } from 'src/app/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-verve-academy-view',
  templateUrl: './verve-academy-view.component.html',
  styleUrls: ['./verve-academy-view.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class VerveAcademyViewComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
