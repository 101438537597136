import { AccountModel } from './../../../../model/account.model';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';

export const RequestAction = createAction('[Member account dropdown] request ');

export const ResponseAction = createAction('[Member account dropdown] response ',
  props<{ payload: AccountModel[] }>());

  export const SelectAccountAction = createAction('[Member account dropdown] select account ',
  props<{ payload: AccountModel }>());

