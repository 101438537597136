import { featureKey } from './../../store/shared.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemberPortalSharedState } from '../../store/shared.states';
import { IUnitPriceViewState } from './state';

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const unitPricesView = createSelector(memberPortalState, (state) => state.unitPriceViewState);

export const unitPricesView_Model = createSelector(
  unitPricesView,
  (state: IUnitPriceViewState) => state.model
);
