
import { IInsurancePreQuestionnaireState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {  onNgrxForms, onNgrxFormsAction, SetValueAction, createFormGroupState, updateGroup, validate, wrapReducerWithFormStateUpdate, setValue} from 'ngrx-forms';
import { required, email, requiredTrue } from 'ngrx-forms/validation';
import { ShowPreQuestionnaireResponseAction,  ShowStartQuestionnaireRequestAction, ResetPreQuestionnaireRequestAction } from './actions';
import { InsurancePreQuestionnaireRequestModel } from 'src/app/model/insurance-questionnaire.model';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'insurancePreQuestionnaireRequestForm';

export const validatePreInsuranceQuestionnaireRequestForm = updateGroup<InsurancePreQuestionnaireRequestModel>({
  hasPreExistingConditionOrDisability :validate(required),
  isPlanningToTakeLeaveWithoutPay:validate(required),
  engageInHazardousPursuits : validate(required)
});

export const state: IInsurancePreQuestionnaireState = {
  form: createFormGroupState(formName, new InsurancePreQuestionnaireRequestModel()),
  showQuestionnaire: false,
  showPreQuestionnaire:true,
  showStartQuestionnaire:false
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetPreQuestionnaireRequestAction, (state) => {
    return {
      ...state,
      showPreQuestionnaire : true,
      showStartQuestionnaire: false
    };
  }),
    on(ShowStartQuestionnaireRequestAction, (state, { display }) => {
      if(!display)
      return;

        return {
        ...state,
        showPreQuestionnaire : !display,
        showStartQuestionnaire: display
      };
  }),
  on(ShowPreQuestionnaireResponseAction, (state, { payload }) => {
    return {
      ...state,
      form: createFormGroupState(formName, new InsurancePreQuestionnaireRequestModel()),
      showQuestionnaire: payload.show,
      showPreQuestionnaire : true,
      showStartQuestionnaire: false
    };
  })
);

export function insurancePreQuestionnaireReducer(state: any | undefined, action: Action) {
  return editInsuranceRequestReducerFormState(state, action);
}

const editInsuranceRequestReducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validatePreInsuranceQuestionnaireRequestForm,
);

