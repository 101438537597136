import { getAccountUnderlyingView_Model } from './selectors';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStates,
  ApexYAxis
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  states: ApexStates;
  tooltip: any;
};

@Component({
  selector: 'app-account-underlying-view',
  templateUrl: './account-underlying-view.component.html',
  styleUrls: ['./account-underlying-view.component.scss']
})
export class AccountUnderlyingViewComponent extends ComponentBase implements OnInit, OnDestroy {

  model$ = this.store.pipe(select(getAccountUnderlyingView_Model));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  @ViewChild("chart1") chart: ChartComponent;

  public chartOptions: Partial<ChartOptions>;

  constructor(public store: Store<MemberPortalSharedState>) {
    super();
    this.chartOptions = {
      series: [
        {
          name: "percentage",
          data: []
        }
      ],
      chart: {
        type: "bar",
        height: 400,

      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "bottom"
          }
        }
      },
      states: {
        // normal: {
        //     filter: {
        //         type: 'none',
        //         value: 0,
        //     }
        // },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          }
        }
        //active: {
        //     allowMultipleDataPointsSelection: false,
        //     filter: {
        //         type: 'darken',
        //         value: 0.35,
        //     }
        // },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["darkslateblue"]
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + '%';
        },
        offsetX: 0,
        dropShadow: {
          enabled: false
        }
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (val, opts) {
              return opts.w.globals.labels[opts.dataPointIndex];
            }
          }
        }
      },
      xaxis: {
        type: 'category'
      },
      yaxis: {
        labels: {
          show: false
        }
      }

    };
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
      }
    });

    this.sub = this.model$.subscribe(x => {

      if (x && this.chart) {
        this.chart.updateSeries([{
          data: x.data
        }])
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}

